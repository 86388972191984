import { useEffect, useState } from "react";
import {
  apiCreateMaintenance,
  apiUpdateMaintenance,
  useApiMaintenance,
  apiDeleteMaintenance,
} from "../../../api/api";
import { MaintenanceData, MaintenanceMode } from "../../../model/maintenance";
import TabContainer from "../../layout/TabContainer";
import SmallButton from "../../input/SmallButton";
import add from "../../../assets/add.png";
import Modal from "../../layout/Modal";
import LargeButton from "../../input/LargeButton";
import MultiselectAccordion from "../../input/MultiselectAccordion";
import { MultiValue } from "react-select";
import { OptionType } from "../../../routes/HostPage";

interface SettingsMaintenanceProps {}

const SettingsMaintenance: React.FC<SettingsMaintenanceProps> = () => {
  const maintenances = useApiMaintenance();
  const [activeMaintenances, setActiveMaintenances] = useState<
    MaintenanceData[]
  >([]);
  const [pastMaintenances, setPastMaintenances] = useState<MaintenanceData[]>(
    []
  );

  useEffect(() => {
    if (maintenances.data && maintenances.data.maintenances) {
      const allMaintenances = maintenances.data.maintenances
        .map((m) => ({
          ...m,
          startTime: new Date(m.startTime),
          endTime: m.endTime ? new Date(m.endTime) : undefined,
          resolvedAt: m.resolvedAt ? new Date(m.resolvedAt) : undefined,
        }))
        .sort((a, b) => {
          return b.startTime.getTime() - a.startTime.getTime();
        });

      const active = allMaintenances.filter((maintenance) => {
        return !maintenance.resolvedAt;
      });
      const past = allMaintenances.filter((maintenance) => {
        return maintenance.resolvedAt;
      });
      setActiveMaintenances(active);
      setPastMaintenances(past);
    }
  }, [maintenances.data]);

  const deleteMaintenance = (id: number) => {
    if (window.confirm("Are you sure you want to delete this maintenance?")) {
      apiDeleteMaintenance(id).then(() => {
        maintenances.refresh();
      });
    }
  };

  const renderTable = (maintenances: MaintenanceData[]) => {
    const includeResolved = maintenances.some(
      (maintenance) => maintenance.resolvedAt
    );
    return (
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Maintenance Mode</th>
            <th>Start Time</th>
            <th>End Time</th>
            {includeResolved && <th>Resolved At</th>}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {maintenances.map((maintenance) => (
            <tr
              key={maintenance.id}
              className={
                !maintenance.resolvedAt && maintenance.startTime <= new Date()
                  ? "maintenance-active"
                  : ""
              }
            >
              <td>{maintenance.id}</td>
              <td>
                {maintenance.maintenance.map((m) => m.toUpperCase()).join(", ")}
              </td>
              <td>{maintenance.startTime.toLocaleString()}</td>
              <td>
                {maintenance.endTime
                  ? maintenance.endTime?.toLocaleString()
                  : "N/A"}
              </td>
              {includeResolved && (
                <td>
                  {maintenance.resolvedAt
                    ? maintenance.resolvedAt?.toLocaleString()
                    : "N/A"}
                </td>
              )}
              <td>
                <div className="button-group">
                  <SmallButton
                    text="Edit"
                    icon={null}
                    isOutlineOnly={true}
                    onClick={() => openEditDialog(maintenance)}
                  />
                  <SmallButton
                    text="Delete"
                    icon={null}
                    isOutlineOnly={true}
                    onClick={() => deleteMaintenance(maintenance.id)}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderActiveMaintenances = () => {
    if (activeMaintenances.length === 0) {
      return <div>No active maintenances</div>;
    }
    return renderTable(activeMaintenances);
  };

  const renderPastMaintenances = () => {
    if (pastMaintenances.length === 0) {
      return <div>No past maintenances</div>;
    }
    return renderTable(pastMaintenances);
  };

  const [id, setId] = useState(0);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [pendingSave, setPendingSave] = useState(false);

  //const [maintenanceMode, setMaintenanceMode] = useState<MaintenanceMode[]>([]);
  const [startTime, setStartTime] = useState<Date | undefined>(undefined);
  const [endTime, setEndTime] = useState<Date | undefined>(undefined);
  const [resolvedAt, setResolvedAt] = useState<Date | undefined>(undefined);

  const maintenanceModeOptions = Object.values(MaintenanceMode).map((mode) => ({
    value: mode,
    label: mode.charAt(0).toUpperCase() + mode.slice(1),
  }));

  const [maintenanceModes, setMaintenanceModes] = useState<
    MultiValue<OptionType>
  >([]);
  const handleMaintenanceModesChange = (selected: MultiValue<OptionType>) => {
    setMaintenanceModes(selected);
    setPendingSave(true);
  };

  const openEditDialog = (maintenance: MaintenanceData) => {
    setId(maintenance.id);
    setResolvedAt(
      maintenance.resolvedAt ? new Date(maintenance.resolvedAt) : undefined
    );

    const initialSelectedMaintenanceModes = maintenanceModeOptions.filter(
      (option) =>
        maintenance.maintenance.includes(option.value as MaintenanceMode)
    );

    setMaintenanceModes(initialSelectedMaintenanceModes);
    setStartTime(new Date(maintenance.startTime!));
    setEndTime(
      maintenance.endTime ? new Date(maintenance.endTime!) : undefined
    );

    setShowEditDialog(true);
    setPendingSave(false);
  };

  const createNewMaintenance = () => {
    setId(0);
    setStartTime(new Date());
    setEndTime(undefined);
    setResolvedAt(undefined);
    setMaintenanceModes([]);

    setShowEditDialog(true);
    setPendingSave(false);
  };

  const saveMaintenance = () => {
    if (!pendingSave) {
      return;
    }

    const maintenance = maintenanceModes.map(
      (mode) => mode.value
    ) as MaintenanceMode[];

    if (maintenance.length === 0) {
      alert("Please select at least one maintenance mode.");
      return;
    }

    if (id === 0) {
      apiCreateMaintenance({
        maintenance,
        startTime: startTime,
        endTime: endTime,
        resolvedAt: resolvedAt,
      }).then(() => {
        maintenances.refresh();
        setPendingSave(false);
        setShowEditDialog(false);
      });
    } else {
      apiUpdateMaintenance({
        id,
        maintenance,
        startTime: startTime,
        endTime: endTime,
        resolvedAt: resolvedAt,
      }).then(() => {
        maintenances.refresh();
        setPendingSave(false);
        setShowEditDialog(false);
      });
    }
  };

  // Add a helper to convert a Date to local datetime string for input type="datetime-local"
  const formatDateTimeLocal = (date?: Date) => {
    if (!date) return "";
    const tzOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - tzOffset).toISOString().slice(0, 16);
  };

  return (
    <>
      <h2>
        Maintenance{" "}
        <SmallButton
          text="Create New Maintenance"
          icon={add}
          isOutlineOnly={true}
          invertIcon={true}
          onClick={() => {
            createNewMaintenance();
          }}
        ></SmallButton>
      </h2>
      <small>
        Maintenance Mode may take up to 5 minutes to take effect and up to 1
        minute to be resolved.
      </small>
      <br />
      <br />
      <TabContainer
        tabs={["Active & Upcoming", "Past"]}
        className="maintenance-tab-container"
      >
        {/* -----Active & Upcoming------ */}
        <>{renderActiveMaintenances()}</>
        {/* -----Past------ */}
        <>{renderPastMaintenances()}</>
      </TabContainer>

      <Modal
        isOpen={showEditDialog}
        onClose={() => {
          setShowEditDialog(false);
        }}
      >
        <br />
        <MultiselectAccordion
          groups={[
            { label: "Maintenance Mode", options: maintenanceModeOptions },
          ]}
          selectedValues={maintenanceModes}
          onChange={handleMaintenanceModesChange}
          defaultOpenSections={["Maintenance Mode"]}
        />

        <small>
          FULL = Temporarily restricts App usage.
          <br />
          PODCASTS = Temporarily restricts generating new Rabbitholes.
        </small>
        <h4>Start Time</h4>
        <small>When does the maintenance start?</small>
        <input
          type="datetime-local"
          value={formatDateTimeLocal(startTime)}
          onChange={(value) => {
            setStartTime(
              value.currentTarget.value
                ? new Date(value.currentTarget.value)
                : undefined
            );
            setPendingSave(true);
          }}
          style={{
            fontSize: 22,
            outline: "none",
            marginTop: 8,
            boxSizing: "border-box",
            width: "100%",
            padding: 12,
          }}
        />
        <h4>Estimated End Time</h4>
        <small>
          This is shown to users as an estimation. You may leave this empty.
        </small>
        <input
          type="datetime-local"
          value={formatDateTimeLocal(endTime)}
          onChange={(value) => {
            setEndTime(
              value.currentTarget.value
                ? new Date(value.currentTarget.value)
                : undefined
            );
            setPendingSave(true);
          }}
          style={{
            fontSize: 22,
            outline: "none",
            marginTop: 8,
            boxSizing: "border-box",
            width: "100%",
            padding: 12,
          }}
        />
        {!!id && (
          <>
            <h4>Maintenance over?</h4>
            <label>
              <input
                type="checkbox"
                checked={resolvedAt !== undefined}
                onChange={(value) => {
                  if (value.currentTarget.checked) {
                    setResolvedAt(new Date());
                  } else {
                    setResolvedAt(undefined);
                  }
                  setPendingSave(true);
                }}
                style={{
                  fontSize: 22,
                  outline: "none",
                  marginTop: 8,
                  boxSizing: "border-box",
                  padding: 12,
                }}
              />
              Once the maintenance is over, check this box and save to resolve
              the maintenance.
            </label>
            <br />
            <br />
          </>
        )}
        <LargeButton
          style={{ marginTop: 16 }}
          text="Save"
          icon={null}
          color="#34A853"
          disabled={!pendingSave || maintenanceModes.length === 0 || !startTime}
          onClick={() => {
            saveMaintenance();
          }}
        />
      </Modal>
    </>
  );
};
export default SettingsMaintenance;
