import "./NavBar.css";
import { useContext } from "react";
import { AuthContext } from "../../context/auth-context";
import logo from "../../assets/logo.png";

import hosts from "../../assets/hosts.png";
import key from "../../assets/key.png";
import activity from "../../assets/activity.png";
import cog from "../../assets/cog.png";
import speechbubble from "../../assets/speechbubble.png";

import { useNavigate } from "react-router-dom";

interface NavBarProps {
  selectedIndex: number;
  isUserForbidden?: boolean;
}

const NavBar: React.FC<NavBarProps> = ({ selectedIndex, isUserForbidden }) => {
  const { currentUser, signOut } = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <div className="navbar">
      {process.env.REACT_APP_ENVIRONMENT === "development" && (
        <div
          style={{
            position: "fixed",
            color: "white",
            backgroundColor: "orange",
            width: "20%",
            left: "50%",
            fontSize: 10,
            padding: 8,
            borderRadius: "8px",
            top: 23,
          }}
        >
          <p style={{ margin: 0 }}>
            This is the STAGING version of admin portal. To make changes to the
            PRODUCTION environment, click{" "}
            <a href="https://admin.storyrabbit.ai">here</a>
          </p>
        </div>
      )}

      <div
        style={{
          height: 20,
          boxSizing: "border-box",
          paddingLeft: 8,
          marginBottom: 24,
        }}
      >
        <img src={logo} alt="logo" width={"80%"} />
      </div>

      {!isUserForbidden && (
        <>
          <div
            onClick={() => {
              navigate("/hosts");
            }}
            className={selectedIndex === 0 ? "navbar-item-selected" : "navbar-item"}
          >
            <img src={hosts} alt="logo" width={"24px"} />
            <p>Hosts</p>
          </div>
          <div
            onClick={() => {
              navigate("/reports");
            }}
            className={selectedIndex === 1 ? "navbar-item-selected" : "navbar-item"}
          >
            <img src={speechbubble} alt="logo" width={"24px"} style={{filter: "invert(1)"}} />
            <p>Reports</p>
          </div>
          <div
            onClick={() => {
              navigate("/promocodes");
            }}
            className={selectedIndex === 2 ? "navbar-item-selected" : "navbar-item"}
          >
            <img src={key} alt="logo" width={"24px"} style={{filter: "invert(1)"}} />
            <p>Promo Codes</p>
          </div>
          <div
            onClick={() => {
              navigate("/analytics");
            }}
            className={selectedIndex === 3 ? "navbar-item-selected" : "navbar-item"}
          >
            <img src={activity} alt="logo" width={"24px"} style={{filter: "invert(1)"}} />
            <p>Analytics</p>
          </div>
          <div
            onClick={() => {
              navigate("/settings");
            }}
            className={selectedIndex === 4 ? "navbar-item-selected" : "navbar-item"}
          >
            <img src={cog} alt="settings" width={"24px"} style={{filter: "invert(1)"}} />
            <p>Settings</p>
          </div>
        </>
      )}

      <div className="navbar-user-container">
        <div
          style={{
            borderRadius: "15px",
            overflow: "hidden",
            height: 30,
            width: 30,
            display: "inline-block",
            transform: "translateY(8px)",
            marginRight: 8,
          }}
        >
          <img src={currentUser?.photoURL ?? ""} alt="profile" height={30} />
        </div>
        <p style={{ display: "inline-block" }}>{currentUser?.displayName}</p>
        <p
          style={{
            color: "#EB4335",
            fontSize: 14,
            cursor: "pointer",
            marginTop: 8,
            marginBottom: 28,
          }}
          onClick={signOut}
        >
          Sign out
        </p>
      </div>
    </div>
  );
};

export default NavBar;
