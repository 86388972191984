import HeaderBar from "../components/layout/Headerbar";
import "./SettingsPage.css";
import NavBar from "../components/layout/NavBar";
import cog from "../assets/cog.png";
import SettingsMaintenance from "../components/settings/maintenance/settingsMaintenance";
import LargeButton from "../components/input/LargeButton";
import { apiExportUserEmails } from "../api/api";
import { useState } from "react";

function SettingsPage() {
  const [isExporting, setIsExporting] = useState(false);

  const handleExportUserEmails = async () => {
    try {
      setIsExporting(true);
      const blob = await apiExportUserEmails();
      
      // Create download link and trigger download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'user-emails.csv';
      document.body.appendChild(a);
      a.click();
      
      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error exporting user emails:", error);
      alert("Failed to export user emails. Please try again.");
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <div className="app">
      <HeaderBar>
        <img
          src={cog}
          alt="Settings"
          height={32}
          style={{
            display: "inline-block",
            marginLeft: 20,
            marginTop: 25,
            filter: "invert(1)",
          }}
        />
        <h1
          style={{
            display: "inline-block",
            marginLeft: 8,
            verticalAlign: "top",
            paddingTop: 13.5,
            fontWeight: 600,
          }}
        >
          Settings
        </h1>
        <div
          style={{
            display: "inline-flex",
            right: 0,
            position: "absolute",
            boxSizing: "border-box",
            padding: 15,
          }}
        ></div>
      </HeaderBar>
      <NavBar selectedIndex={4} />

      <div className="content" style={{ paddingLeft: 50, paddingRight: 50 }}>
        <SettingsMaintenance />
        <hr style={{ marginTop: 30 }} />
        <div style={{ marginTop: 30 }}>
          <h2>User Management</h2>
          <div style={{ marginTop: 20 }}>
            <h3>User Emails</h3>
            <p>Download a CSV file containing all user email addresses.</p>
            <div style={{ marginTop: 10 }}>
              <LargeButton 
                text="Export User Emails" 
                icon={null} 
                color="#4a90e2"
                onClick={handleExportUserEmails}
                loading={isExporting}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingsPage;
