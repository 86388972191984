import { apiEndpoints, mapsApiKey } from "../constants";
import { PersonaModel } from "../model/persona";
import { PlaceDetail, PlacesResponse } from "../model/poi";

import { UploadResponseModel } from "../model/upload-response";
import { PersonaVoice } from "../model/voice";
import { apiDelete, apiPost, apiPut, useGet, apiGet } from "./method";
import { ReportModel } from "../model/report";
import { PromoCodeModel } from "../model/promocode";
import { Analytics } from "../model/analytics";
import { MaintenanceData } from "../model/maintenance";
import { getUserToken } from "../firebase/firebase";

// Get all personas
export const useApiPersonas = () =>
  useGet<Array<PersonaModel>>(apiEndpoints.PERSONA);

// Get single persona
export const useApiPersona = (id: string) =>
  useGet<PersonaModel>(apiEndpoints.PERSONA + "/" + id);

// Create persona
export const apiCreatePersona = async (persona: PersonaModel) => {
  return await apiPost<PersonaModel>(apiEndpoints.PERSONA, persona);
};

// Update personas order
export const apiReorderPersonas = async (order: any) => {
  return await apiPost<any>(apiEndpoints.PERSONA_ORDER, order);
};

// Clone persona
export const apiClonePersona = async (personaId: number) => {
  return await apiPost<PersonaModel>(
    apiEndpoints.PERSONA + "/" + personaId.toString() + "/clone",
    {}
  );
};

// Delete persona
export const apiDeletePersona = async (personaId: number) => {
  return await apiDelete<any>(
    apiEndpoints.PERSONA + "/" + personaId.toString()
  );
};

// Update persona
export const apiUpdatePersona = async (persona: PersonaModel) => {
  return await apiPut<PersonaModel>(
    apiEndpoints.PERSONA + "/" + persona.id.toString(),
    persona,
    null
  );
};

export const apiCheckAppleStoreSubscriptionExists = async (
  personaId: number
) => {
  return await apiGet<any>(
    apiEndpoints.PERSONA + "/" + personaId.toString() + "/subscription"
  );
};

// Clone persona
export const apiUploadPersonaImage = async (filename: string) => {
  return await apiPost<UploadResponseModel>(apiEndpoints.PERSONA_IMAGE, {
    filename: filename,
  });
};

export const apiUploadPersonaAudio = async (filename: string) => {
  return await apiPost<UploadResponseModel>(apiEndpoints.PERSONA_AUDIO, {
    filename: filename,
  });
};

// Upload file to url
export const apiUploadFile = async (uploadUrl: string, data: Blob) => {
  return await apiPut<any>(uploadUrl, data, {
    "Content-Type": data.type,
  });
};

// Get voices
export const useApiVoices = () =>
  useGet<Array<PersonaVoice>>(apiEndpoints.VOICES);

export const apiGetPoisBoundingBox = async (
  lat: number,
  lon: number,
  poiTypes: string[]
) => {
  var resultPOIs: PlaceDetail[] = [];
  try {
    const fieldMasks = [
      "places.name",
      "places.displayName",
      "places.formattedAddress",
      "places.address_components",
      "places.editorialSummary",
      "places.location",
      "places.photos",
      "places.types",
    ];
    const fieldMaskString = fieldMasks.join(",");

    const requestData = {
      includedTypes: poiTypes,
      maxResultCount: 19,
      locationRestriction: {
        circle: {
          center: {
            latitude: lat,
            longitude: lon,
          },
          radius: 50000,
        },
      },
      rankPreference: "POPULARITY",
    };

    const response = await fetch(
      "https://places.googleapis.com/v1/places:searchNearby",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Goog-Api-Key": mapsApiKey,
          "X-Goog-FieldMask": fieldMaskString,
        },
        body: JSON.stringify(requestData),
      }
    );
    if (response.ok) {
      const data: PlacesResponse = await response.json();
      if (data.places && data.places.length > 0) {
        for (const place of data.places) {
          if (!resultPOIs.find((it) => it.name === place.name))
            resultPOIs.push(place);
        }
      }
    }
  } catch (error) {
    console.error("Error:", error);
  }
  return resultPOIs;
};

// Get POIs
export const apiGetPois = async (
  lat: number,
  lon: number,
  count: number,
  poiTypes: string[]
) => {
  var resultPOIs: PlaceDetail[] = [];
  try {
    const searchRadiusMeters = [50, 100, 200, 500, 1000];
    const fieldMasks = [
      "places.name",
      "places.displayName",
      "places.formattedAddress",
      "places.address_components",
      "places.editorialSummary",
      "places.location",
      "places.photos",
      "places.types",
    ];
    const fieldMaskString = fieldMasks.join(",");
    for (const radius of searchRadiusMeters) {
      if (resultPOIs.length >= count) {
        // ignore: avoid_print
        console.log("Place polling reached target count at radius " + radius);
        break;
      }
      const requestData = {
        includedTypes: poiTypes,
        maxResultCount: count,
        locationRestriction: {
          circle: {
            center: {
              latitude: lat,
              longitude: lon,
            },
            radius: radius,
          },
        },
        rankPreference: "POPULARITY",
      };
      const response = await fetch(
        "https://places.googleapis.com/v1/places:searchNearby",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Goog-Api-Key": mapsApiKey,
            "X-Goog-FieldMask": fieldMaskString,
          },
          body: JSON.stringify(requestData),
        }
      );
      if (response.ok) {
        const data: PlacesResponse = await response.json();
        if (data.places && data.places.length > 0)
          for (const place of data.places) {
            if (resultPOIs.length >= count) break;
            if (!resultPOIs.find((it) => it.name === place.name))
              resultPOIs.push(place);
          }
      }
    }
  } catch (error) {
    console.error("Error:", error);
  }
  return resultPOIs;
};

// Get Reports
export const useApiReports = () =>
  useGet<Array<ReportModel>>(apiEndpoints.REPORTS);

// Promo Codes
export const useApiPromoCodes = () =>
  useGet<{ promoCodes: Array<PromoCodeModel> }>(apiEndpoints.PROMO_CODES);

export const apiCreatePromoCode = async (data: Partial<PromoCodeModel>) => {
  return await apiPost<any>(apiEndpoints.PROMO_CODES, data);
};

export const apiUpdatePromoCode = async (data: Partial<PromoCodeModel>) => {
  if (!data.id) {
    throw new Error("Promo code ID is required");
  }
  return await apiPut<any>(
    apiEndpoints.PROMO_CODES + "/" + data.id.toString(),
    data,
    null
  );
};

export const apiDeletePromoCode = async (id: number) => {
  return await apiDelete<any>(apiEndpoints.PROMO_CODES + "/" + id.toString());
};

export const useApiAnalytics = () =>
  useGet<{ analytics: Analytics }>(apiEndpoints.ANALYTICS);

export const useApiMaintenance = () =>
  useGet<{ maintenances: MaintenanceData[] }>(apiEndpoints.MAINTENANCE);

export const apiCreateMaintenance = async (data: Partial<MaintenanceData>) => {
  return await apiPost<{ maintenance: MaintenanceData }>(
    apiEndpoints.MAINTENANCE,
    data
  );
};

export const apiUpdateMaintenance = async (data: Partial<MaintenanceData>) => {
  if (!data.id) {
    throw new Error("Maintenance ID is required");
  }
  return await apiPut<{ maintenance: MaintenanceData }>(
    apiEndpoints.MAINTENANCE + "/" + data.id.toString(),
    data,
    null
  );
};

export const apiDeleteMaintenance = async (id: number) => {
  return await apiDelete<any>(apiEndpoints.MAINTENANCE + "/" + id.toString());
};

export const apiExportUserEmails = async () => {
  try {
    const Authorization = await getUserToken();
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}${apiEndpoints.EXPORT_USER_EMAILS}`,
      {
        method: "GET",
        headers: {
          Accept: "text/csv",
          Authorization,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to export user emails");
    }

    // Return the blob for the component to handle
    return await response.blob();
  } catch (error) {
    console.error("Error exporting user emails:", error);
    throw error;
  }
};
