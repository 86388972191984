export enum MaintenanceMode {
  FULL = "full",
  PODCASTS = "podcasts",
}
export interface MaintenanceData {
  id: number;
  maintenance: MaintenanceMode[];
  startTime: Date;
  endTime?: Date;
  resolvedAt?: Date;
  updatedAt: Date;
  createdAt: Date;
}
