//console.log("ENV for debugging purposes...");

export const apiBaseURL =
  process.env.REACT_APP_API_URL || "https://dev.api.storyrabbit.ai";

export const poiBaseURL =
  process.env.REACT_APP_MS_POI_URL || "https://dev.poi.storyrabbit.ai";

export const webSocketBaseUrl =
  process.env.REACT_APP_MS_POI_URL?.replace("https://", "wss://") ||
  "wss://dev.poi.storyrabbit.ai"; //TODO move replace elsewhere if we need this base url for other purposes too

export const apiEndpoints = {
  PERSONA: "/admin/persona",
  PERSONA_IMAGE: "/admin/persona-image",
  PERSONA_AUDIO: "/admin/persona-audio-sample",
  VOICES: "/admin/voices",
  REPORTS: "/admin/report",
  PERSONA_ORDER: "/admin/persona-order",
  PROMO_CODES: "/admin/promocode",
  ANALYTICS: "/admin/analytics",
  MAINTENANCE: "/admin/maintenance",
  EXPORT_USER_EMAILS: "/admin/export/user-emails",
};

export const mapsApiKey = process.env.REACT_APP_MAPS_API_KEY || "";
