import React from "react";

import "./SmallButton.css";

interface SmallButtonProps {
  text: string;
  icon: string | null;
  isOutlineOnly: boolean;
  onClick: () => void;
  className?: string;
  invertIcon?: boolean;
  style?: React.CSSProperties;
}

const SmallButton: React.FC<SmallButtonProps> = ({
  text,
  onClick,
  icon,
  isOutlineOnly,
  invertIcon = false,
  className,
  style = {},
}) => {
  if (isOutlineOnly)
    return (
      <button
        className={`small-button-outline ${className}`}
        onClick={onClick}
        style={style}
      >
        {icon != null && (
          <img
            src={icon}
            alt="Icon"
            className={
              "small-button-icon-outline" + (invertIcon ? " icon-inverted" : "")
            }
          />
        )}

        {text}
      </button>
    );
  return (
    <button
      className={`small-button ${className}`}
      onClick={onClick}
      style={style}
    >
      {icon != null && (
        <img
          src={icon}
          alt="Icon"
          className={"small-button-icon" + (invertIcon ? " icon-inverted" : "")}
        />
      )}

      {text}
    </button>
  );
};

export default SmallButton;
